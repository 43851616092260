import React from "react";
import { useSelector } from "react-redux";
import { ProductFruits } from "react-product-fruits";

import { selectProfile } from "../../redux/ducks/appDuck";

export default function WidgetProductFruits() {
  const profile = useSelector(selectProfile);

  const userInfo = {
    username: profile?.userName,
    email: profile?.userName,
    firstname: profile?.employee?.firstName,
    lastname: profile?.employee?.lastName,
    signUpAt: Date.now(),
    role: profile?.userRole,
  };

  if (!profile || !process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE) return null;
  return (
    <ProductFruits
      workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE}
      language="en"
      user={userInfo}
    />
  );
}

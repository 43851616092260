import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authService, logErrorsService, urlHelpers } from "./core";
import { selectCpaClient, selectProfile } from "./redux/ducks/appDuck";
import querystring from "query-string";
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { signalRAutoStartAndCleanUp, SignalRContext } from "./utils/signalR";
import { useLocation } from "react-router";
import { localStorageSyncApi } from "./core/localStorageSyncApi";
import { sessionStorageSyncApi } from "./core/sessionStorageSyncApi";

function SignalRProvider(props) {
  const profile = useSelector(selectProfile);
  const selectedCpaClientId = useSelector(selectCpaClient);
  const [signalRConnection, setSignalRConnection] = useState();
  const location = useLocation();

  const companyId = profile && profile.conum;
  const employeeCompanyNumber =
    profile &&
    profile.employee &&
    profile.employee.empConum &&
    parseInt(profile.employee.empConum, 10);
  const employeeNumber =
    profile && profile.employee && profile.employee.empnum && parseInt(profile.employee.empnum, 10);
  const version = profile && profile.version;

  // SignalR
  useEffect(() => {
    try {
      const conumOrCpa = authService.isCpaUser() ? selectedCpaClientId : companyId;
      const qsData = {
        conum: conumOrCpa,
        empConum: employeeCompanyNumber,
        empnum: employeeNumber,
        version,
        reactAppVersion: process.env.REACT_APP_VERSION,
        jwtGuid: authService.getJwtGuid(),
        currentUrl: location.pathname,
        bfp: localStorageSyncApi.getOrSetBfp(),
        sfp: sessionStorageSyncApi.getOrSetSfp()
      };
      const qs = querystring.stringify(qsData);
      const connection = new HubConnectionBuilder()
        .withUrl(urlHelpers.getAbsoluteURL(`/hub/Main?${qs}`), {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => authService.getToken()
        })
        .configureLogging("warn")
        .build();

      const userId = authService.isUserAuthenticated();
      if (conumOrCpa && userId) {
        setSignalRConnection(connection);
        return signalRAutoStartAndCleanUp(connection);
      }
    } catch (err) {
      logErrorsService.error("Error connecting signalR");
    }
  }, [companyId, selectedCpaClientId]);

  return (
    <SignalRContext.Provider value={signalRConnection}>{props.children}</SignalRContext.Provider>
  );
}

export default SignalRProvider;

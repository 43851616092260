import { useSelector } from "react-redux";
import { selectIsLoading, selectProfile, setProfile } from "../../redux/ducks/appDuck";
import { useContext, useEffect, useMemo } from "react";
import { authService, constants, logErrorsService } from "../../core";
import { localStorageSyncApi } from "../../core/localStorageSyncApi";
import { sessionStorageSyncApi } from "../../core/sessionStorageSyncApi";
import { PAGES } from "../../routes/pages";
import { JL } from "jsnlog";
import { selectSnackbar } from "../../redux/ducks/snackbarDuck";
import { useSnackbar } from "notistack";
import {
  getErrorSnackbarOptions,
  getSuccessSnackbarOptions,
} from "../../utils/snackbarNotifications";
import { useLocation, useHistory } from "react-router";
import { SignalRContext } from "../../utils/signalR";
import ErrorBoundaryComponent from "../ErrorBoundary/ErrorBoundary";
import { redirectToLoginTimerClear, redirectToLoginTimerStart } from "../../utils/redirectToLogin";
import { useDispatch } from "react-redux";
import { openNewDownloadModalAC } from "../../redux/ducks/widgetNewDownloadDuck";
import useAccountsApi from "../../api/accountsApi";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

export const RootLayout = (props) => {
  const profile = useSelector(selectProfile);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => selectSnackbar(state));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const signalRConnection = useContext(SignalRContext);
  const accountsApi = useAccountsApi();
  const showLoading = useSelector(selectIsLoading);

  const children = useMemo(() => {
    return props.children;
  }, [props]);

  useEffect(() => {
    setInspectletProperties(profile);
    setClarityProperties(profile);
    const logLevel = profile?.logLevel;
    switch (logLevel) {
      case "info":
        logErrorsService.setLogLevel(JL.getInfoLevel());
        break;
      default:
        logErrorsService.setLogLevel(JL.getInfoLevel());
        break;
    }
  }, [profile]);

  useEffect(() => {
    // Hide Google reCAPTCHA if logged in
    if (
      authService.isCompanyAuthenticated() ||
      authService.isCpaUser() ||
      authService.isEssUser()
    ) {
      if (!document.querySelector("body").classList.contains("logged-in")) {
        document.querySelector("body").classList.add("logged-in");
      }
    } else if (document.querySelector("body").classList.contains("logged-in")) {
      document.querySelector("body").classList.remove("logged-in");
    }

    // Hide Chat Support link if on login page
    if (location.pathname === PAGES.brandsLogin.path) {
      if (!document.querySelector("body").classList.contains("login-page")) {
        document.querySelector("body").classList.add("login-page");
      }
    } else if (document.querySelector("body").classList.contains("login-page")) {
      document.querySelector("body").classList.remove("login-page");
    }
  }, [location]);

  useEffect(() => {
    const unregisterHistoryListener = history.listen(async (location, action) => {
      logErrorsService.info("route-change", { location, action });
      try {
        if (signalRConnection?.connectionState !== "Disconnected") {
          await signalRConnection?.invoke("SetUrl", location.pathname);
        }
      } catch (err) {
        logErrorsService.error("error in SetUrl", err);
      }
    });
    return () => {
      unregisterHistoryListener();
    };
  }, []);

  useEffect(() => {
    if (snackbar.show === true) {
      if (snackbar.variant === "success") {
        enqueueSnackbar(snackbar.message, getSuccessSnackbarOptions(closeSnackbar));
      } else if (snackbar.variant === "failure") {
        enqueueSnackbar(snackbar.message, getErrorSnackbarOptions(closeSnackbar));
      }
    }
  }, [snackbar]);

  // SignalR
  useEffect(() => {
    if (signalRConnection) {
      try {
        signalRConnection.on("ReportNotification", (data) => {
          dispatch(openNewDownloadModalAC(data));
        });
        signalRConnection.on("ForceRefreshConnection", () => {
          window.location.reload(true);
        });
      } catch (err) {
        logErrorsService.error("error in LayoutMain useEffect signalR");
      }
    }
  }, [signalRConnection]);

  useEffect(() => {
    const getProfile = async () => await accountsApi.getProfile();
    const isCompanyAuthenticated = authService.isCompanyAuthenticated();
    const isCpaUser = authService.isCpaUser();
    const isEssUser = authService.isEssUser();
    if ((isCompanyAuthenticated || isCpaUser || isEssUser) && !profile) {
      getProfile();
      redirectToLoginTimerStart(history, `${location.pathname}${location.search}`);
    } else {
      dispatch(setProfile(null));
    }
    return () => {
      return redirectToLoginTimerClear;
    };
  }, []);

  useEffect(() => {
    let lockResolver;
    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise((res) => {
        lockResolver = res;
      });

      navigator.locks.request("brands_lock", { mode: "shared" }, () => {
        return promise;
      });
    }
  }, []);

  return (
    <ErrorBoundaryComponent isPage>
      <>
        {showLoading && <LoadingScreen />}
        {children}
      </>
    </ErrorBoundaryComponent>
  );
};

function setInspectletProperties(profile) {
  if (constants.IS_PRODUCTION && window.__insp) {
    const bfp = localStorageSyncApi.getOrSetBfp();
    const sfp = sessionStorageSyncApi.getOrSetSfp();
    const jwtGuid = authService.getJwtGuid();
    window.__insp.push(["identify", { userName: profile?.userName }]);
    window.__insp.push(["tagSession", { conum: profile?.conum }]);
    window.__insp.push(["tagSession", { ClientApp_Version: process.env.REACT_APP_VERSION }]);
    window.__insp.push(["tagSession", { API_Version: profile?.version }]);
    window.__insp.push(["tagSession", { bfp, sfp }]);
    jwtGuid && window.__insp.push(["tagSession", { JWT_Guid: jwtGuid }]);
  }
}

function setClarityProperties(profile) {
  if (!window?.clarity) return;

  window.clarity("set", "bfp", localStorageSyncApi.getOrSetBfp());
  window.clarity("set", "sfp", sessionStorageSyncApi.getOrSetSfp());
  window.clarity("set", "ClientApp_Version", process.env.REACT_APP_VERSION);

  const jwtGuid = authService.getJwtGuid();
  if (jwtGuid) window.clarity("set", "JWT_Guid", jwtGuid);

  if (profile) {
    window.clarity(
      "identify",
      profile?.userName,
      localStorageSyncApi.getOrSetBfp(),
      null,
      `${profile.employee?.firstName} ${profile.employee?.lastName}`
    );
  } else {
    window.clarity("identify", null, localStorageSyncApi.getOrSetBfp());
  }

  if (!profile) return;
  window.clarity("set", "user_name", profile?.userName);
  window.clarity("set", "API_Version", profile?.version);
  if (profile?.conum) {
    window.clarity("set", "conum", profile?.conum.toString());
  }
}

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";
import App from "./App";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

LicenseInfo.setLicenseKey(
  "31138f439701c5687f4c1e9366b82a1eTz04MjcwNixFPTE3Mzc1NjY0ODIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

root.render(<App />);

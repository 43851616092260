import React from "react";
import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { authService } from "../../core";
import { PAGES } from "../../routes/pages";
import { logErrorsService } from "../../core";
import { logErrorsApi } from "../../api/logErrorsApi";

export const ErrorBoundaryComponentPropTypes = {
  children: PropTypes.node.isRequired
};

function ErrorFallback({ error, resetErrorBoundary, isPage }) {
  const handleClickDashboard = () => {
    window.location.replace(PAGES.dashboard.path);
  };

  const handleClickLogout = () => {
    authService.removeToken();
    window.location.replace(PAGES.brandsLogin.path);
  };

  return (
    <Paper sx={{ maxWidth: 650, textAlign: "left", margin: "auto", padding: 2 }}>
      <Box sx={{ fontSize: "1.4rem", color: "grey.900", fontWeight: "bold" }}>Error 500</Box>
      <Box sx={{ marginTop: 2.5, fontSize: "1.4rem", color: "purpleDark.main" }}>
        <b>Oops</b> something went wrong...
      </Box>
      <Box sx={{ marginTop: 0.5, color: "grey.900" }}>
        Our engineering team has been alerted to this issue, and will work promptly to resolve it
      </Box>
      <Box sx={{ marginTop: 3.75 }}>
        <Button onClick={resetErrorBoundary}>Try Again</Button>
        {isPage && (
          <>
            <Button onClick={handleClickDashboard} sx={{ marginLeft: 1 }}>
              Dashboard
            </Button>
            <Button onClick={handleClickLogout} sx={{ marginLeft: 1 }}>
              Logout
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
}

const StyledErrorFallback = ErrorFallback;

const myErrorHandler = (error, info) => {
  console.error(error, info);
  const url = window.location.href;
  info = JSON.stringify({ error, info });
  logErrorsService.error(error.message, info);
  logErrorsApi.push(error.message, url, info);
};

function ErrorBoundaryComponent({ isPage = false, children }) {
  return (
    <ErrorBoundary
      FallbackComponent={props => <StyledErrorFallback {...props} isPage={isPage} />}
      onReset={() => { }}
      onError={myErrorHandler}
    >
      {children}
    </ErrorBoundary>
  );
}

ErrorBoundaryComponent.propTypes = ErrorBoundaryComponentPropTypes;

export default ErrorBoundaryComponent;

// ACTION TYPES
export const TYPES = {
  OPEN_MANAGE_PROFILE: "OPEN_MANAGE_PROFILE",
  CLOSE_MANAGE_PROFILE: "CLOSE_MANAGE_PROFILE"
};

// ACTION CREATORS
export const openManageProfileAC = () => ({ type: TYPES.OPEN_MANAGE_PROFILE });
export const closeManageProfileAC = () => ({ type: TYPES.CLOSE_MANAGE_PROFILE });

// REDUCER
const initialState = {
  isManageProfileDrawerOpened: false
};

export const widgetManageProfile = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.OPEN_MANAGE_PROFILE:
      return {
        ...state,
        isManageProfileDrawerOpened: true
      };
    case TYPES.CLOSE_MANAGE_PROFILE:
      return {
        ...state,
        isManageProfileDrawerOpened: false
      };
    default:
      return state;
  }
};

// SELECTORS
export const selectIsManageProfileOpened = state => state.widgetManageProfile.isManageProfileDrawerOpened

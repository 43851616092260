import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import { PAGES } from "./pages";
import { Switch } from "react-router-dom";

const PagePayrolls = React.lazy(() => import("../pages/payrolls"));
const PagePayrollsPowerImports = React.lazy(() => import("../pages/payrolls/power-imports"));
const PagePayrollsManualChecks = React.lazy(() => import("../pages/payrolls/manual-checks"));
const PagePayrollsManualCheck = React.lazy(() =>
  import("../pages/payrolls/manual-checks/[checkKey]")
);
const PagePayroll = React.lazy(() => import("../pages/payrolls/[prnum]"));
const PagePayrollSummary = React.lazy(() => import("../pages/payrolls/[prnum]/summary"));
const PageStartPayroll = React.lazy(() => import("../pages/payrolls/new"));
const PagePreferences = React.lazy(() => import("../pages/payrolls/preferences"));
const PagePreferencesLists = React.lazy(() => import("../pages/payrolls/preferences/lists"));
const PagePreferencesNewList = React.lazy(() => import("../pages/payrolls/preferences/new-list"));

const PayrollRoutes = ({ permissions }) => {
  return (
    <Switch>
      <ProtectedRoute
        path={`${PAGES.payrollsManualChecks.path}/:checkKey`}
        title={PAGES.payrollsManualChecks.title}
        permission={permissions?.paydeckPayroll}
        component={PagePayrollsManualCheck}
      />

      <ProtectedRoute
        path={`${PAGES.payrollStart.path}/:step`}
        title={PAGES.payrollStart.title}
        permission={permissions?.paydeckPayroll}
        component={PageStartPayroll}
      />
      <ProtectedRoute
        exact
        disabled={false}
        path={PAGES.payrollsPowerImports.path}
        title={PAGES.payrollsPowerImports.title}
        permission={permissions?.paydeckPayroll}
        component={PagePayrollsPowerImports}
      />

      <ProtectedRoute
        exact
        disabled={false}
        path={PAGES.payrollsManualChecks.path}
        title={PAGES.payrollsManualChecks.title}
        permission={permissions?.paydeckPayroll}
        component={PagePayrollsManualChecks}
      />
      <ProtectedRoute
        exact
        disabled={false}
        path={PAGES.payrollsPreferences.path}
        title={PAGES.payrollsPreferences.title}
        permission={permissions?.paydeckPayroll}
        component={PagePreferences}
      />
      <ProtectedRoute
        exact
        disabled={false}
        path={PAGES.payrollsList.path}
        title={PAGES.payrollsList.title}
        permission={permissions?.paydeckPayroll}
        component={PagePreferencesLists}
      />
      <ProtectedRoute
        exact
        disabled={false}
        path={PAGES.newPayrollsList.path}
        title={PAGES.newPayrollsList.title}
        permission={permissions?.paydeckPayroll}
        component={PagePreferencesNewList}
      />
      <ProtectedRoute
        exact
        disabled={false}
        path={`${PAGES.editPayrollsList.path}/:filterId`}
        title={PAGES.editPayrollsList.title}
        permission={permissions?.paydeckPayroll}
        component={PagePreferencesNewList}
      />

      <ProtectedRoute
        path={`${PAGES.payrolls.path}/:prnum/summary`}
        title={PAGES.payrolls.title}
        permission={permissions?.paydeckPayroll}
        component={PagePayrollSummary}
      />

      <ProtectedRoute
        path={`${PAGES.payrolls.path}/:prnum`}
        title={PAGES.payrolls.title}
        permission={permissions?.paydeckPayroll}
        component={PagePayroll}
      />

      <ProtectedRoute
        path={PAGES.payrolls.path}
        title={PAGES.payrolls.title}
        permission={permissions?.paydeckPayroll}
        component={PagePayrolls}
      />
    </Switch>
  );
};

export default PayrollRoutes;

import { reportHistoryApi } from "../api/reportHistoryApi";
import federalStateFormsIcon from "../assets/icons/federal_state_forms_icon.svg";
import generatedReportsIcon from "../assets/icons/generated_reports_icon.svg";
import distributedW2sIcon from "../assets/icons/distributed_w2s_icon.svg";
import payrollReportsIcon from "../assets/icons/payroll_reports_icon.svg";
import distributedPayStubsIcon from "../assets/icons/distributed_pay_stubs_icon.svg";
import generateReportIcon from "../assets/icons/generate_report_icon.svg";

export const MAIN_CATEGORIES = [
  {
    id: "payroll",
    name: "Payroll Reports",
    description: "The package of reports that comes with each payroll",
    href: "payroll",
    get: reportHistoryApi.getPayrollReports,
    icon: payrollReportsIcon,
  },
  {
    id: "distributed-pay-stubs",
    name: "Distributed Pay Stubs",
    description: "Pay stubs emailed or web posted to individual employees",
    href: "distributed-pay-stubs",
    get: reportHistoryApi.getDistributedPayStubsReport,
    icon: distributedPayStubsIcon,
  },
  {
    id: "period-end",
    name: "Federal & State Forms",
    description: "Quarterly federal and state forms",
    href: "period-end",
    get: reportHistoryApi.getPeriodEndReports,
    icon: federalStateFormsIcon,
  },
  {
    id: "distributed-w2-forms",
    name: "Distributed W-2's",
    description: "W2 forms distributed to individual employees",
    href: "distributed-w2-forms",
    get: reportHistoryApi.getDistributedW2s,
    icon: distributedW2sIcon,
  },
];

export const SECONDARY_CATEGORIES = [
  {
    id: "run-v2",
    name: "Generate a Report",
    description: "Run and SEARCH for reports highlighting the details you need",
    href: "run-v2",
    icon: generateReportIcon,
  },
  {
    id: "posted",
    name: "Generated Reports",
    description: "After running a report, you can access it here",
    href: "posted",
    icon: generatedReportsIcon,
  },
];

export const REDIRECT_TO = {
  dashboard: 0,
  selectCompany: 1,
  setupPhoneNumber: 2,
  confirmPhoneNumber: 3,
  login: 4,
  resetPassword: 5,
  emailNotConfirmed: 6,
  ipRestricted: 7,
  onboarding: 8,
  twoFactorAuth: 9,
  cpaPortal: 10,
  addCpaDetails: 11,
  addCpaUserName: 12,
  twoFactorAuthSetup: 13,
  essDashboard: 14,
};
